import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Layout from '../../components/layout';
import ArticlesComponent from '../../components/articles';
import '../../assets/css/main.css';
import styled from 'styled-components';
import { Cursor } from '../../components/Cursor';
import { Menu } from '../../components/Menu';
import { PageHeader } from '..';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Logo } from '../../components/Logo';

const IndexPage = () => {
  const data = useStaticQuery(query);
  const [background, setBackground] = React.useState();
  const [delayedBackground, setDelayedBackground] = React.useState();
  const [isHoveringMenu, setIsHoveringMenu] = React.useState(false);

  return (
    <Layout seo={data.strapiProject.seo}>
      <Cursor active={isHoveringMenu} />
      <div className='uk-section'>
        <Menu isHovering={(hovering) => setIsHoveringMenu(hovering)} />
        <Container className='uk-container uk-container-large'>
          <Logo
            imageData={data.strapiGlobal.logo}
            isHovering={(hovering) => setIsHoveringMenu(hovering)}
          />

          <div style={{ display: 'grid' }}>
            <StyledImage
              src={
                delayedBackground ||
                data.strapiProject.workLanding[0].Image.localFile.url
              }
              style={{ gridArea: '1/1', objectFit: 'cover' }}
              alt={``}
              layout='fullWidth'
            />
            <StyledImage
              src={background}
              style={{ gridArea: '1/1', objectFit: 'cover' }}
              alt={``}
              layout='fullWidth'
            />
            <StyledWorkLandingList>
              {[
                ...data.strapiProject.workLanding,
                ...data.strapiProject.workLanding,
                ...data.strapiProject.workLanding,
              ].map((l) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <a
                    href={l.URL}
                    onMouseOver={() => {
                      setBackground(l.Image.localFile.url);
                      setTimeout(
                        () => setDelayedBackground(l.Image.localFile.url),
                        100
                      );
                    }}
                  >
                    {l.Title}
                  </a>
                </div>
              ))}
            </StyledWorkLandingList>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiGlobal {
      logo {
        localFile {
          url
          childImageSharp {
            gatsbyImageData(width: 500, quality: 100, placeholder: NONE)
          }
        }
      }
    }
    strapiProject {
      workLanding {
        Title
        URL
        Image {
          localFile {
            url
            childImageSharp {
              gatsbyImageData(width: 1350, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  padding-left: 230px;
  padding-right: 230px;
  @media (max-width: 1200px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const StyledImage = styled.img`
  transition: all 0.2s ease;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
`;

const StyledWorkLandingList = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  z-index: 1;
  font-size: 55px;
  padding: 0px 20px 90px;
  width: calc(100% - 40px);
  text-align: center;
  padding-top: 0px;
  margin-top: 80px;
  height: calc(100% - 389px);
  scrollbar-width: none;
  overflow: scroll;
  left: 50%;
  transform: translateX(-50%);
  & a {
    color: black;
    height: 65px;
  }

  @media (max-width: 1200px) {
    font-size: 45px;
    padding: 0px 10px 90px;
    width: calc(100% - 20px);
    height: calc(100% - 289px);
  }

  @media (max-width: 600px) {
    font-size: 30px;
    padding: 0px 10px 90px;
    width: calc(100% - 20px);
    a {
      height: 50px;
    }
  }
`;

export default IndexPage;
